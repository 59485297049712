import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const CarouselStyles = withTheme(() => (
  <Global
    styles={css`
      .quote-slide-carousel {
        .leshen-carousel {
          .button {
            box-shadow: none;
            background-color: transparent;
            padding: 6px;

            svg {
              min-width: 42px;
              min-height: 42px;
              position: absolute;
              right: -5px;
              top: -15px;

              path {
                fill: #a0105c;
              }
            }
          }

          .button:focus {
            outline: none;
          }
        }
      }

      .quote-slide {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    `}
  />
))

export default CarouselStyles
