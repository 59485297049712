import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const HeaderStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      .top-bar {
        justify-content: center !important;
        text-align: center;
      }

      .leshen-header {
        .leshen-link-button-wrapper {
          .leshen-phone-cta,
          .leshen-link-button {
            margin-left: auto;
            padding: 0;
            span {
              color: #a0105c;
            }

            &:hover {
              background-color: transparent;
              color: inherit;
            }
          }
        }
      }
      /*
      .leshen-mobilenav {
        button {
          span {
            margin-bottom: 0;
          }
        }

        .leshen-link {
          background-color: #a0105c !important;
          color: #ffffff !important;
        }
      }

      .left-cta {
        position: absolute;
        text-align: right;
        bottom: 24px;
        margin-right: 16px !important;
        right: 48px;
        color: #1b0b54;

        @media screen and (min-width: ${theme.breakpoints.xl}) {
          right: calc((100% - 1200px) / 2);
        }
      }

      .clearlink-header {
        background-color: #f1f2f3;

        .wrapper {
          padding: 16px 0;
          text-align: center;
          max-width: 1200px;
          margin: 0 auto;
        }
      } */
    `}
  />
))

export default HeaderStyles
