import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const BannerCss = withTheme(({ theme }) => (
  <Global
    styles={css`
      .cta-banner {
        &.only-cta {
          .leshen-link {
            margin-top: 0 !important;
          }
        }
      }

      .call-center-hours-button {
        padding: 8px 16px;
        padding-top: 12px;
        padding-bottom: 12px;
        border-radius: 4px;
        background-color: ${theme.colors.light.base.hex};
        color: ${theme.colors.primary.base.hex} !important;
        text-decoration: none;
        font-size: 16px;

        &:hover {
          text-decoration: none;
        }
      }
    `}
  />
))

export default BannerCss
