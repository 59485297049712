import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const SplitContentCss = withTheme(() => (
  <Global
    styles={css`
      .split-content {
        .reverse-desktop {
          .split-content__grid {
            @media (min-width: 767px) {
              grid-template-areas: 'image content';
            }
          }
        }

        &.hide-tablet-image {
          @media (min-width: 540px) and (max-width: 767px) {
            .split-content__image {
              display: none;
            }
          }
        }
      }
    `}
  />
))

export default SplitContentCss
