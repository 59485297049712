import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const FormStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-form-submission-message {
        text-align: center;
      }

      .avail-form {
        &.margin-left-0 {
          .leshen-form {
            margin-left: 0 !important;
          }
        }
      }
    `}
  />
))

export default FormStyles
