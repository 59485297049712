import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const FooterStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      .leshen-footer {
        background-color: #1b0b54 !important;

        .top-section {
          padding-top: 24px;
          padding-bottom: 24px;
          border-bottom: 1px solid #a0105c;
        }

        .bottom-section {
          background-color: #1b0b54 !important;
          color: #ffffff;

          .nav {
            p {
              font-size: 18px;
              line-height: 24px;
            }

            .link {
              color: #ffffff;
            }
          }
        }

        .footer-logo {
          max-width: 272px;
          max-height: 64px;

          @media screen and (max-width: ${theme.breakpoints.md}) {
            max-width: 154px;
            max-height: 36px;
          }
        }

        .legal-section {
          background-color: #050210;
          color: #ffffff;
        }
      }
    `}
  />
))

export default FooterStyles
