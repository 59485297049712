import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const BillboardCss = withTheme(({ theme }) => (
  <Global
    styles={css`
      .hero-content {
        &.v2-index {
          .leshen-typography-h1,
          .leshen-typography-h4 {
            color: #fff;

            @media screen and (max-width: ${theme.breakpoints.lg}) {
              color: #1b0b54;
            }
          }
        }

        .leshen-billboard-section-full {
          @media screen and (max-width: ${theme.breakpoints.md}) {
            padding-top: 0;
          }

          .content {
            background-color: transparent;
            box-shadow: none;
          }
        }
      }
    `}
  />
))

export default BillboardCss
