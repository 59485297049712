import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const ColumnsCss = withTheme(({ theme }) => (
  <Global
    styles={css`
      .card-padding {
        .content {
          padding: 24px 12px !important;
          margin-bottom: 0 !important;

          @media screen and (min-width: ${theme.breakpoints.lg}) {
            padding: 24px !important;
          }
        }
      }

      .leshen-carousel-item-container {
        .card-padding {
          @media screen and (max-width: ${theme.breakpoints.lg}) {
            margin: 0 16px !important;
            height: 100%;
          }
        
        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 100%;

          .content-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    `}
  />
))

export default ColumnsCss
