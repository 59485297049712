import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const GlobalCss = withTheme(({ theme }) => (
  <Global
    styles={css`
      .leshen-typography-h1,
      .leshen-typography-h2,
      .leshen-typography-h3,
      .leshen-typography-h4,
      .leshen-typography-h5,
      .leshen-typography-h6 {
        font-weight: 700 !important;
        margin-bottom: ${theme.spacing.md};
      }

      .leshen-typography-body {
        font-weight: 400 !important;
      }

      .brand-logos,
      .trust-symbols {
        padding-top: ${theme.spacing.sm} !important;
        padding-bottom: ${theme.spacing.sm} !important;
        justify-items: center;

        @media screen and (min-width: ${theme.breakpoints.md}) {
          padding-top: ${theme.spacing.md} !important;
          padding-bottom: ${theme.spacing.md} !important;
        }

        &.header {
          padding-top: ${theme.spacing.lg} !important;
          padding-bottom: ${theme.spacing.lg} !important;

          @media screen and (min-width: ${theme.breakpoints.md}) {
            padding-top: ${theme.spacing.xxl} !important;
            padding-bottom: ${theme.spacing.xxl} !important;
          }

          .content {
            margin-bottom: 0;
            width: 100%;
          }
        }
      }

      .trust-symbols {
        div: {
          max-width: 417px;
        }
      }

      .margin-x-auto {
        margin-left: auto;
        margin-right: auto;
      }

      .margin-x-auto-small {
        @media screen and (max-width: ${theme.breakpoints.md}) {
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }

      .cta-banner {
        div:first-of-type {
          display: flex;
          flex-direction: column;
          gap: 0;
        }

        .text-content {
          text-align: center;
          font-size: 24px;
          line-height: 32px;
          font-weight: 700;

          h4 {
            margin-bottom: 0;
          }
        }

        .module {
          a {
            text-align: center;
            font-size: 24px;
            line-height: 32px;
            font-weight: 700;
            text-decoration: none;
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }

          a:hover {
            text-decoration: underline;
          }
        }
      }

      .leshen-variable-content {
        .content {
          margin-bottom: 32px;
        }
      }

      .leshen-column-wrapper {
        .content {
          @media screen and (max-width: ${theme.breakpoints.md}) {
            padding: 0 !important;
          }
        }
      }

      .leshen-column-wrapper:last-of-type {
        .content {
          @media screen and (max-width: ${theme.breakpoints.md}) {
            margin-bottom: 24px;
          }
        }
      }

      // I know this probably looks weird, but if you nest multiple
      // List components inside ListItems, it does weird things to the layout
      .leshen-list {
        display: contents !important;

        &.padding-left {
          padding-left: 24px;
        }
      }

      .leshen-list-item {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 16px !important;

        &.margin-bottom-0 {
          margin-bottom: 0 !important;
        }

        svg {
          fill: #1b0b54;
        }
      }

      .leshen-form {
        .submit-button {
          @media screen and (max-width: ${theme.breakpoints.md}) {
            margin-top: 0;
          }
        }
      }

      .margin-bottom-0 {
        margin-bottom: 0 !important;
      }

      .margin-top-0 {
        margin-top: 0 !important;
      }

      .margin-bottom-sm {
        margin-bottom: ${theme.spacing.sm} !important;
      }

      button[color='light'] {
        color: #a0105c;
      }

      button[color='light']:hover {
        background-color: #f1f2f3;
        color: #80114c;
      }

      .show-on-mobile {
        display: block;

        @media screen and (min-width: ${theme.breakpoints.lg}) {
          display: none;
        }
      }

      .show-on-desktop {
        display: none;

        @media screen and (min-width: ${theme.breakpoints.lg}) {
          display: block;
        }
      }

      .text-align-center-desktop {
        @media screen and (min-width: ${theme.breakpoints.lg}) {
          text-align: center;
        }
      }

      .product-icons {
        &.centered {
          margin-left: auto;
          margin-right: auto;
        }
      }

      strong {
        font-weight: bolder;
        font-size: 17px;
      }

      .breadcrumbs {
        top: 32px;
        padding: 0;
      }

      .background-color-white,
      .background-color-light {
        .leshen-link {
          color: ${theme.colors.dark.base.hex} !important;
        }
        .leshen-link:visited {
          color: ${theme.colors.primary.base.hex} !important;
        }
      }

      .padding-bottom-0 {
        padding-bottom: 0 !important;
      }

      .text-primary {
        color: ${theme.colors.primary.base.hex} !important;
      }

      .padding-left {
        padding-left: 24px;
      }

      // necessary to keep longer links from breaking onto separate line
      .inline {
        display: inline !important;
      }
    `}
  />
))

export default GlobalCss
